import React, { useContext, useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { pdfjs } from "react-pdf";
import axios from "axios";
import MyContext from "../context/appContext";
import { Document, Page } from "react-pdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton } from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPropertiesPdf() {
  const location = useLocation();
  const appContext = useContext(MyContext);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const [originalPdfUrls, setOriginalPdfUrls] = useState([]);
  const [numPages, setNumPages] = useState(0);
  const [pdfUrl, setPdfURL] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [row, setRow] = useState(null);

  useEffect(() => {
    const rowData = location.state?.row;
    console.log(rowData, "thumbnailBlobs");
    if (rowData && rowData.thumbnailUrl?.length > 0) {
      setRow(rowData);
    } else {
      console.warn("No thumbnail URLs found on initial load.");
    }
  }, [location.state]);

  useEffect(() => {
    if (row && row.thumbnailUrl?.length > 0) {
      fetchData();
    }
  }, [row]);

  const fetchData = async () => {
    appContext.updateState("loading", true);
    try {
      if (Array.isArray(row?.thumbnailUrl)) {
        const thumbnailBlobs = await Promise.all(
          row.thumbnailUrl.map(async (item) => {
            const blob = await generatePdfThumbnailFromUrl(item.url);
            return {
              url: URL.createObjectURL(blob),
              fileName: item.fileName,
              originalUrl: item.url,
            };
          })
        );

        setThumbnailUrls(thumbnailBlobs);
        const fullPdfUrls = thumbnailBlobs.map(
          ({ originalUrl }) => originalUrl
        );
        setOriginalPdfUrls(fullPdfUrls);

        appContext.updateState("loading", false);
      } else {
        const thumbnailBlob = await generatePdfThumbnailFromUrl(
          row?.thumbnailUrl.url
        );
        const url = URL.createObjectURL(thumbnailBlob);

        setThumbnailUrls([{ url, fileName: row?.thumbnailUrl.fileName }]);
        setOriginalPdfUrls([row?.thumbnailUrl.url]);

        appContext.updateState("loading", false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      appContext.updateState("loading", false);
    }
  };

  const generatePdfThumbnailFromUrl = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "arraybuffer" });
      const pdfData = new Uint8Array(response?.data);

      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      setNumPages(pdf.numPages);
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 0.5 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    } catch (error) {
      console.error("Error generating PDF thumbnail:", error);
      throw error;
    }
  };

  const onDownloadPdf = async (index) => {
    try {
      const pdfUrl = originalPdfUrls[index];
      if (!pdfUrl) {
        console.error("PDF URL not found", index);
        return;
      }
      const response = await axios.get(pdfUrl, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `property_${index + 1}.pdf`;
      link.click();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const onOpenPdfInNewTab = (url, index) => {
    setPdfURL(originalPdfUrls[index]);
    setShowPdf(true);
  };

  return (
    <div style={{ margin: "5rem 4rem" }}>
      {/* {showPdf ? (
        <div>
          <div style={{ margin: "auto" }}>
            <Button onClick={() => setShowPdf(false)}>Close</Button>
          </div>
          <Document file={pdfUrl}>
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                style={{ margin: 0 }}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      ) : ( */}
      <Grid container spacing={4}>
        {thumbnailUrls?.map((url, index) => (
          <Grid item xs={3} className="Grid-Gap" key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <img
                onClick={() => onOpenPdfInNewTab(url.url, index)}
                key={index}
                src={url.url}
                alt="Thumbnail"
                style={{
                  width: "100%",
                  height: "80%",
                  borderRadius: "10px",
                  marginBottom: "1rem",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4 style={{ margin: "0px", marginTop: "0rem" }}>
                    {url.fileName
                      ? url.fileName.split("-").pop().replace(".pdf", "")
                      : ""}
                  </h4>
                </div>
                <IconButton
                  aria-label="edit"
                  size="small"
                  style={{ color: "#1976d2" }}
                  onClick={() => onDownloadPdf(index)}
                >
                  <FileDownloadIcon />
                </IconButton>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      {/* )} */}
    </div>
  );
}

export default ViewPropertiesPdf;
