import styled from "styled-components";
import laptopImage from "../assets/laptop-image.png"; // Ensure this image is in your assets folder
import backgroundImage from "../assets/background-image.png"; // Ensure this image is in your assets folder
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  let navigate = useNavigate();
  return (
    <PageWrapper>
      <Header>
        <Logo>
          <img src="/faviconPNG.png" style={{ height: "30px" }} alt="Logo" />
        </Logo>
        <Nav>
          <NavItem>FEATURES</NavItem>
          <NavItem>BROKER DEALERS</NavItem>
          <NavItem>RESOURCES</NavItem>
          <NavItem>CONTACT</NavItem>
          <LoginButton onClick={() => navigate("/login")}>LOG IN</LoginButton>
        </Nav>
      </Header>
      <MainContent>
        <LeftContent>
          <div
            className="login-signin-btn"
            style={{ flexDirection: "column", alignItems: 'flex-start' }}
          >
            <Title className="custom-font">
              A Better Way To Illustrate Alternative Investments
            </Title>
            <div>
              <GetStartedButton>GET STARTED</GetStartedButton>
            </div>
          </div>
        </LeftContent>
        <RightContent>
          <LaptopImage
            src={laptopImage}
            alt="Laptop showing AltsIllustrator interface"
          />
        </RightContent>
      </MainContent>
    </PageWrapper>
  );
};

// Styles

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const NavItem = styled.a`
  color: #fff;
  margin-left: 30px;
  text-decoration: none;
  font-size: 13px;
  cursor: pointer;
  font-weight: bold;

`;

const LoginButton = styled.button`
  background-color: #a48c28;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 30px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 4%;
  @media (max-width: 1024px) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 4vw;
  color: #fff;
  max-width: 700px;
  margin-bottom: 30px;
  font-weight: 200;
  word-spacing: 15px;

  @media (max-width: 768px) {
    font-size: 5vw;

  }
`;

const GetStartedButton = styled.button`
  background-color: #a48c28;
  color: #fff;
  border: none;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
`;

const RightContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
width: 50%;
  @media (max-width: 1024px) {
    margin-top: 30px;
  }
`;

const LaptopImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export default LandingPage;