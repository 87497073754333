import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { API } from "aws-amplify";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

function TaxYieldCalculator() {
  let navigate = useNavigate();
  const [otherInfo, setOtherInfo] = useState({
    client: "",
    date: new Date().toISOString().slice(0, 10),
    equity: "",
    debt: "",
    total: "",
    ltv: "",
    assumedIncomTax: "",
    annualDepreciation: "",
    purchasePrice: "",
    valueOfImprovements: "",
    propertyType: "",
    estimatedAnnualDepreciation: "",
    newEquityInvestment: "",
  });

  const [tableRows, setTableRows] = useState(() => {
    const initialRows = [...Array(1)].map(() => ({
      id: "",
      equity: "",
      debt: "",
      yield: "",
      improvementRatio: "",
      property: "",
      annualDepreciation1: "",
      annualDepreciation2: "",
    }));
    return initialRows;
  });

  const [dsts, setDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [equityDifference, setEquityDifference] = useState(0);

  useEffect(() => {
    const totalEquity = otherInfo.equity.replace(/[^0-9.]/g, "");
    const sumEquity = tableRows.reduce((acc, row) => {
      const rowEquity =
        row.equity.trim() === ""
          ? 0
          : parseFloat(row.equity.replace(/[^0-9.]/g, ""));
      return acc + rowEquity;
    }, 0);
    setEquityDifference(Math.round(totalEquity - sumEquity));
  }, [otherInfo, tableRows]);

  useEffect(() => {
    getIdData();
  }, []);

  const getIdData = async () => {
    try {
      setLoading(true);
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      const activeDsts = res.filter((item) => item.is_active !== false);
      const sortedDsts = activeDsts.sort((a, b) =>
        a?.basicInfo?.fullLegalNameOfDst?.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );
      setDsts(sortedDsts);
    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeOtherInfo = (e) => {
    setOtherInfo({ ...otherInfo, [e.target.name]: e.target.value });
  };

  const applyMask = (inputVal) => {
    const cleanInput = inputVal.replace(/,/g, "").replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput.split(".");
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };

  const handleTotalChange = (event) => {
    const { name, value } = event.target;
    const inputVal = event.target.value;
    const maskedInput = applyMask(inputVal);
    const updatedOtherInfo = {
      ...otherInfo,
      [name]: maskedInput,
    };

    const cleanedEquity = updatedOtherInfo.equity.replace(/[^0-9.]/g, "");
    const cleanedDebt = updatedOtherInfo.debt.replace(/[^0-9.]/g, "");

    if (cleanedEquity !== "" && cleanedDebt !== "") {
      const total = parseFloat(cleanedEquity) + parseFloat(cleanedDebt);
      updatedOtherInfo.total = total.toLocaleString();
      updatedOtherInfo.ltv =
        ((parseFloat(cleanedDebt) / total) * 100).toFixed(2) + "%";
    } else {
      updatedOtherInfo.total = "";
      updatedOtherInfo.ltv = "";
    }

    // Annual Depreciation Handling
    const cleanedAnnualDepreciation =
      updatedOtherInfo.annualDepreciation.replace(/[^0-9.]/g, "");
    const cleanedPurchasePrice = updatedOtherInfo.purchasePrice.replace(
      /[^0-9.]/g,
      ""
    );

    const cleanedValueOfImprovements = updatedOtherInfo.valueOfImprovements
      .trim()
      .replace(/[^0-9.]/g, "");
    const propertyType = updatedOtherInfo.propertyType.trim();
    if (
      cleanedAnnualDepreciation !== "" &&
      parseFloat(cleanedAnnualDepreciation) > 0
    ) {
      updatedOtherInfo.estimatedAnnualDepreciation = parseFloat(
        cleanedAnnualDepreciation
      ).toLocaleString();
      updatedOtherInfo.newEquityInvestment =
        (
          (parseFloat(cleanedAnnualDepreciation) / parseFloat(cleanedEquity)) *
          100
        ).toFixed(2) + "%";
    } else if (
      (cleanedAnnualDepreciation === "" || +cleanedAnnualDepreciation < 1) &&
      cleanedPurchasePrice !== "" &&
      cleanedValueOfImprovements !== "" &&
      propertyType !== ""
    ) {
      if (propertyType === "Residential") {
        const estimatedNewValue =
          (updatedOtherInfo.estimatedAnnualDepreciation = (
            (parseFloat(cleanedPurchasePrice) *
              (parseFloat(cleanedValueOfImprovements) / 100)) /
            27.5
          ).toFixed());
        updatedOtherInfo.estimatedAnnualDepreciation =
          parseFloat(estimatedNewValue).toLocaleString();
        updatedOtherInfo.newEquityInvestment =
          (
            (parseFloat(estimatedNewValue) / parseFloat(cleanedEquity)) *
            100
          ).toFixed(2) + "%";
      } else if (propertyType === "Commercial") {
        const estimatedNewValue =
          (updatedOtherInfo.estimatedAnnualDepreciation = (
            (parseFloat(cleanedPurchasePrice) *
              (parseFloat(cleanedValueOfImprovements) / 100)) /
            39
          ).toFixed());
        updatedOtherInfo.estimatedAnnualDepreciation =
          parseFloat(estimatedNewValue).toLocaleString();
        updatedOtherInfo.newEquityInvestment =
          (
            (parseFloat(estimatedNewValue) / parseFloat(cleanedEquity)) *
            100
          ).toFixed(2) + "%";
      } else {
        const estimatedNewValue =
          (updatedOtherInfo.estimatedAnnualDepreciation = (
            (parseFloat(cleanedPurchasePrice) *
              (parseFloat(cleanedValueOfImprovements) / 100)) /
            35
          ).toFixed());
        updatedOtherInfo.estimatedAnnualDepreciation =
          parseFloat(estimatedNewValue).toLocaleString();
        updatedOtherInfo.newEquityInvestment =
          (
            (parseFloat(estimatedNewValue) / parseFloat(cleanedEquity)) *
            100
          ).toFixed(2) + "%";
      }
    } else {
      updatedOtherInfo.estimatedAnnualDepreciation = "";
      updatedOtherInfo.newEquityInvestment = "";
    }

    setOtherInfo(updatedOtherInfo);
  };

  const handleDropdownChange = (event, index) => {
    const selectedId = event.target.value;
    const selectedItem = dsts.find((item) => item.id === selectedId);
    setSelectedItem((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = selectedItem;
      return updatedItems;
    });
    const cleanedDebt = selectedItem?.basicInfo?.offeringDebt.replace(
      /[^0-9.]/g,
      ""
    );
    const cleanedEquity = selectedItem?.basicInfo?.offeringEquity.replace(
      /[^0-9.]/g,
      ""
    );
    setTableRows((prevRows) =>
      prevRows?.map((row, i) => {
        if (i === index) {
          return {
            id: selectedId,
            name: selectedItem?.basicInfo?.fullLegalNameOfDst,
            equity: "",
            debt: "",
            total: "",
            yield: selectedItem?.yields?.year1Yield.replace("%", ""),
            improvementRatio: "",
            property: "",
            annualDepreciation1: "",
            annualDepreciation2: "",
          };
        } else {
          return row;
        }
      })
    );
    setErrMessage(false);
  };

  const handleEquityChange = (event, index) => {
    const newEquityValue = event.target.value;
    const maskedInput = applyMask(newEquityValue);
    const cleanedEquity = maskedInput.replace(/[^0-9.]/g, "");
    const selectedItems = selectedItem[index];
    const cleanedDebt = selectedItems?.basicInfo?.offeringDebt.replace(
      /[^0-9.]/g,
      ""
    );
    const cleanedEquityForm = selectedItems?.basicInfo?.offeringEquity.replace(
      /[^0-9.]/g,
      ""
    );
    const y = cleanedEquity / cleanedEquityForm;
    const debt = Number((y * cleanedDebt).toFixed(2)).toLocaleString("en-US");
    const cleanedDebtCalc = debt.replace(/[^0-9.]/g, "");
    setTableRows((prevRows) =>
      prevRows.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            equity: maskedInput,
            debt: debt,
            total: (+cleanedEquity + +cleanedDebtCalc).toLocaleString(),
          };
        } else {
          return row;
        }
      })
    );
  };

  useEffect(() => {
    const hasEmptyEquityValue = tableRows.some((row) => row.equity === "");
    setShowAddButton(tableRows.length < 200 && !hasEmptyEquityValue);
  }, [tableRows]);

  const handleAddRow = () => {
    setShowAddButton(false);
    setTableRows((prevRows) => [
      ...prevRows,
      {
        id: "",
        equity: "",
        debt: "",
        yield: "",
        improvementRatio: "",
        property: "",
        annualDepreciation1: "",
        annualDepreciation2: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    setTableRows((prevRows) => {
      if (prevRows.length > 1) {
        const updatedRows = prevRows.filter((_, i) => i !== index);
        const updatedRowsWithTotal = updatedRows.map((row) => {
          return {
            ...row,
            total: (row.equity || 0) + (row.debt || 0) === 0 ? "" : row.total,
          };
        });
        return updatedRowsWithTotal;
      }
      return prevRows;
    });
  };

  useEffect(() => {
    const storedState = JSON.parse(sessionStorage.getItem("equityCalculator"));
    if (storedState) {
      setTableRows(storedState.tableRows);
      setOtherInfo(storedState.otherInfo);
      if (storedState.selectedItem) {
        setSelectedItem(storedState.selectedItem);
      }
    }
  }, []);

  const handleAddData = () => {
    const rowsWithNonEmptyIds = tableRows.filter((row) => row.id !== "");
    if (rowsWithNonEmptyIds.length > 0) {
      sessionStorage.setItem(
        "equityCalculator",
        JSON.stringify({
          tableRows: rowsWithNonEmptyIds,
          otherInfo,
          selectedItem,
        })
      );
      navigate("/tax-equivalent-yield-calculator-print-view", {
        state: {
          tableRows: rowsWithNonEmptyIds,
          otherInfo,
        },
      });
    } else {
      setErrMessage(true);
    }
  };

  const handleClearField = (index) => {
    const emptyRow = {
      id: "",
      equity: "",
      debt: "",
      total: "",
      yield: "",
      improvementRatio: "",
      property: "",
      annualDepreciation1: "",
      annualDepreciation2: "",
    };
    const updatedTableRows = tableRows.map((row, i) =>
      i === index ? emptyRow : row
    );
    setTableRows(updatedTableRows);
  };

  const cleanNumeric = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }
    return parseFloat(value.replace(/[^0-9.]/g, ""));
  };

  const columnTotals = {
    equity: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.equity), 0)
    ),
    debt: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.debt), 0)
    ),
    total: cleanNumeric(
      tableRows?.reduce((total, row) => +total + cleanNumeric(row.total), 0)
    ),
    improvementRatio: tableRows?.reduce(
      (total, row) =>
        total +
        (cleanNumeric(row.improvementRatio) * cleanNumeric(row.equity)) /
          cleanNumeric(otherInfo.equity),
      0
    ),
    yield: parseFloat(
      (
        tableRows?.reduce((total, row) => {
          const yieldValue = cleanNumeric(row.yield);
          const equity = cleanNumeric(row.equity);

          return total + yieldValue * equity;
        }, 0) / cleanNumeric(otherInfo.equity)
      ).toFixed(2)
    ),

    annualDepreciation1: cleanNumeric(
      tableRows?.reduce(
        (total, row) => +total + cleanNumeric(row.annualDepreciation1),
        0
      )
    ),
    annualDepreciation2: parseFloat(
      (
        tableRows?.reduce((total, row) => {
          const dep2Value = cleanNumeric(row.annualDepreciation2);
          const equity = cleanNumeric(row.equity);

          return total + dep2Value * equity;
        }, 0) / cleanNumeric(otherInfo.equity)
      ).toFixed(2)
    ),
  };

  const totalRow = {
    name: "Totals (Weighted):",
    equity: +columnTotals.equity || 0,
    debt: +columnTotals.debt,
    total: +columnTotals.total,
    improvementRatio: +columnTotals.improvementRatio.toFixed(2),
    yield: +columnTotals.yield,
    annualDepreciation1: +columnTotals.annualDepreciation1,
    annualDepreciation2: +columnTotals.annualDepreciation2.toFixed(2),
  };

  const totalRowLabel = {
    name: "",
    equity: "Total Equity",
    debt: "Total Debt",
    total: "Total Value",
    improvementRatio: "Total Ave. Weighted  Improvement Ratio",
    yield: "Ave. Weighted First-Year  Yield",
    property: "",
    annualDepreciation1: "Est. Max. Ann. Depreciation",
    annualDepreciation2: "Expressed as % of new equity investment",
  };

  const handleSessionClear = () => {
    sessionStorage.clear("equityCalculator");
    setTableRows([]);
    setOtherInfo({
      client: "",
      date: new Date().toISOString().slice(0, 10),
      equity: "",
      debt: "",
      total: "",
      ltv: "",
      assumedIncomTax: "",
      annualDepreciation: "",
      purchasePrice: "",
      valueOfImprovements: "",
      propertyType: "",
      estimatedAnnualDepreciation: "",
      newEquityInvestment: "",
    });
  };

  const handleImprovementRatioChange = (index, value) => {
    const updatedRows = [...tableRows];
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    updatedRows[index].improvementRatio = cleanedValue;

    const propertyType = updatedRows[index].property;

    const total =
      parseFloat(updatedRows[index].total.replace(/[^0-9.]/g, "")) || 0;

    const improvementRatio = parseFloat(cleanedValue) || 0;

    const Cleanedequity =
      parseFloat(updatedRows[index].equity.replace(/[^0-9.]/g, "")) || 0;

    let depreciationFactor = 1;
    if (propertyType === "Residential") {
      depreciationFactor = 27.5;
    } else if (propertyType === "Commercial") {
      depreciationFactor = 39;
    } else if (propertyType === "Blended") {
      depreciationFactor = 35;
    }

    const annaulFinalValue = (
      (+total * (+improvementRatio / 100)) /
      +depreciationFactor
    ).toFixed(2);

    if (Cleanedequity > 0 && propertyType !== "" && improvementRatio > 0) {
      updatedRows[index].annualDepreciation1 =
        parseFloat(annaulFinalValue).toLocaleString();

      updatedRows[index].annualDepreciation2 = (
        (+annaulFinalValue / +Cleanedequity) *
        100
      ).toFixed(2);
    } else {
      updatedRows[index].annualDepreciation1 = "";
      updatedRows[index].annualDepreciation2 = "";
    }

    setTableRows(updatedRows);
  };

  const formatImprovementRatio = (value) => {
    if (!value) return "";
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const parts = cleanedValue.split(".");
    if (parts.length > 2) {
      return parts[0] + "." + parts[1];
    }

    return cleanedValue;
  };

  const handlePropertyTypeChange = (index, value) => {
    const updatedRows = [...tableRows];
    updatedRows[index].property = value;

    const total =
      parseFloat(updatedRows[index].total.replace(/[^0-9.]/g, "")) || 0;

    const improvementRatio =
      parseFloat(updatedRows[index].improvementRatio) || 0;

    const Cleanedequity =
      parseFloat(updatedRows[index].equity.replace(/[^0-9.]/g, "")) || 0;

    let depreciationFactor = 1;
    if (value === "Residential") {
      depreciationFactor = 27.5;
    } else if (value === "Commercial") {
      depreciationFactor = 39;
    } else if (value === "Blended") {
      depreciationFactor = 35;
    }

    const annaulFinalValue = (
      (+total * (+improvementRatio / 100)) /
      +depreciationFactor
    ).toFixed();

    if (Cleanedequity > 0 && value !== "" && improvementRatio > 0) {
      updatedRows[index].annualDepreciation1 =
        parseFloat(annaulFinalValue).toLocaleString();

      updatedRows[index].annualDepreciation2 = (
        (+annaulFinalValue / +Cleanedequity) *
        100
      ).toFixed(2);
    } else {
      updatedRows[index].annualDepreciation1 = "";
      updatedRows[index].annualDepreciation2 = "";
    }

    setTableRows(updatedRows);
  };

  return (
    <>
      <div style={{ margin: "2rem" }}>
        <Grid container spacing={2}>
          <Card
            style={{
              width: "100%",
              minHeight: "85vh",
              marginTop: "1rem",
              marginLeft: "1rem",
              padding: "1rem 0rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "1.5rem",
              }}
            >
              <h4 style={{ margin: "1rem 2rem", textTransform: "uppercase" }}>
                Tax Equivalent Yield Calculator
              </h4>
              <div
                style={{
                  margin: "0rem 2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                  }}
                >
                  <Button
                    onClick={handleAddData}
                    type="submit"
                    variant="contained"
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    View Report
                  </Button>
                  <Button
                    onClick={handleSessionClear}
                    type="submit"
                    variant="contained"
                    style={{
                      marginLeft: "0.6rem",
                      marginTop: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    Reset
                  </Button>
                </div>
                {errMessage ? (
                  <Alert severity="error">
                    Atleast one of the DST should be selected!
                  </Alert>
                ) : null}
              </div>
            </div>
            <div style={{ margin: "0rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <h4>Prepared For</h4>
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="client"
                    label="Prepared For"
                    value={otherInfo.client}
                    onChange={handleChangeOtherInfo}
                  />
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="date"
                    type="date"
                    value={otherInfo.date}
                    onChange={(e) =>
                      setOtherInfo({
                        ...otherInfo,
                        date: e.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Date"
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "1rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <h4>Client Downleg</h4>
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Equity"
                    name="equity"
                    value={otherInfo.equity}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Debt"
                    name="debt"
                    value={otherInfo.debt}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Total"
                    name="total"
                    value={otherInfo.total}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="LTV"
                    name="ltv"
                    value={otherInfo.ltv}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "0rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4} className="Grid-Gap">
                  <h4>Assumed Income Tax Bracket (fed+state)</h4>
                </Grid>
                <Grid item xs={4} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    name="assumedIncomTax"
                    label="Tax Bracket %"
                    value={otherInfo.assumedIncomTax}
                    onChange={handleTotalChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "1rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={2} className="Grid-Gap"></Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <p>If known, enter annual depreciation deduction here:</p>
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Annual $ Depreciation if Known"
                    name="annualDepreciation"
                    value={otherInfo.annualDepreciation}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={1.5} className="Grid-Gap">
                  <p>OR enter these estimated values:</p>
                </Grid>
                <Grid item xs={1.5} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Original purchase price"
                    name="purchasePrice"
                    value={otherInfo.purchasePrice}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={1.3} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Estimated % value of improvements"
                    name="valueOfImprovements"
                    value={otherInfo.valueOfImprovements}
                    helperText={"CA Default = 50%"}
                    onChange={handleTotalChange}
                  />
                </Grid>
                <Grid item xs={1.7} className="Grid-Gap">
                  <TextField
                    select
                    size="small"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: otherInfo.propertyType ? (
                        <IconButton
                          aria-label="clear"
                          size="small"
                          onClick={() => {
                            handleTotalChange({
                              target: {
                                name: "propertyType",
                                value: "",
                              },
                            });
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : null,
                    }}
                    label="Property Type"
                    value={otherInfo.propertyType}
                    onChange={handleTotalChange}
                    name="propertyType"
                    SelectProps={{
                      IconComponent: otherInfo.propertyType
                        ? () => null
                        : undefined,
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {["Residential", "Commercial", "Blended"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "1rem 2rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} className="Grid-Gap"></Grid>
                <Grid item xs={2.5} className="Grid-Gap">
                  <h4>Estimated Annual Depreciation</h4>
                </Grid>
                <Grid item xs={2} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="Estimated Annual Depreciation"
                    name="estimatedAnnualDepreciation"
                    value={otherInfo.estimatedAnnualDepreciation}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={1.5} className="Grid-Gap">
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    label="%"
                    name="newEquityInvestment"
                    helperText={"Expressed as % of new equity investment"}
                    value={otherInfo.newEquityInvestment}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ margin: "0rem 0rem" }}>
              <Grid>
                <div
                  style={{
                    margin: "0rem 0rem",
                    marginTop: "4rem",
                    marginRight: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  <Grid>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      {showAddButton ? (
                        <Button
                          onClick={handleAddRow}
                          type="submit"
                          variant="contained"
                          style={{
                            margin: "0rem 0rem",
                            padding: "0.1rem 0.5rem",
                            marginBottom: "0.5rem",
                            marginRight: "0.8rem",
                            textTransform: "inherit",
                          }}
                        >
                          Add DST
                        </Button>
                      ) : null}
                    </div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Proposed Portfolio
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Equity
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Debt
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Est. Improvement Ratio
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Yield
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Property Type
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Estimated Annual Depreciation
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                            }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableRows?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              style={{
                                borderBottom: "none",
                                padding: "6px",
                                width: "17%",
                              }}
                            >
                              <div>
                                <TextField
                                  select
                                  size="small"
                                  margin="normal"
                                  fullWidth
                                  InputProps={{
                                    style: { width: "15rem" },
                                    endAdornment: row.id ? (
                                      <IconButton
                                        aria-label="clear"
                                        size="small"
                                        onClick={() => handleClearField(index)}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    ) : null,
                                  }}
                                  style={{ margin: "0px" }}
                                  label="Select DST"
                                  value={row.id}
                                  onChange={(event) =>
                                    handleDropdownChange(event, index)
                                  }
                                  SelectProps={{
                                    IconComponent: row.id
                                      ? () => null
                                      : undefined,
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300,
                                        },
                                      },
                                    },
                                  }}
                                >
                                  {loading ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <CircularProgress size={20} />
                                    </Box>
                                  ) : (
                                    dsts?.map((option, ind) => (
                                      <MenuItem key={ind} value={option.id}>
                                        {option.basicInfo.fullLegalNameOfDst}
                                      </MenuItem>
                                    ))
                                  )}
                                </TextField>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                {row.id ? (
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                    }}
                                    value={row.equity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    disabled
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      cursor: "not-allowed",
                                    }}
                                    value={row.equity}
                                    onChange={(event) =>
                                      handleEquityChange(event, index)
                                    }
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.debt}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.total}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  onChange={(e) =>
                                    handleImprovementRatioChange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                  value={formatImprovementRatio(
                                    row.improvementRatio
                                  )}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.yield}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <TextField
                                select
                                size="small"
                                variant="outlined"
                                InputProps={{
                                  style: { width: "10rem" },
                                }}
                                style={{ margin: "0px" }}
                                label="Property Type"
                                value={row.property}
                                onChange={(e) =>
                                  handlePropertyTypeChange(
                                    index,
                                    e.target.value
                                  )
                                }
                                name="property"
                              >
                                <MenuItem value="" key="">
                                  Clear
                                </MenuItem>
                                {["Residential", "Commercial", "Blended"].map(
                                  (option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  )
                                )}
                              </TextField>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.annualDepreciation1}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ borderBottom: "none", padding: "6px" }}
                            >
                              <div>
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                  value={row.annualDepreciation2}
                                  InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "center",
                                borderBottom: "none",
                                padding: "6px",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                disabled={index > 0 ? false : true}
                                size="large"
                                color="#fff"
                                onClick={() => handleRemoveRow(index)}
                              >
                                <DeleteIcon style={{ color: "#ff5555" }} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRow.name}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.equity
                              ? Math.round(totalRow.equity).toLocaleString(
                                  "en-US"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.debt
                              ? Math.round(totalRow.debt).toLocaleString(
                                  "en-US"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            ${" "}
                            {totalRow.total
                              ? Math.round(totalRow.total).toLocaleString(
                                  "en-US"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.improvementRatio
                              ? `${totalRow.improvementRatio}%`
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.yield ? `${totalRow.yield}%` : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.annualDepreciation1
                              ? Math.round(
                                  totalRow.annualDepreciation1
                                ).toLocaleString("en-US")
                              : ""}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            {totalRow.annualDepreciation2
                              ? `${totalRow.annualDepreciation2}%`
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.name}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.equity}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            $ <b>{totalRowLabel.debt}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.total}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.improvementRatio}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.yield}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.property}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.annualDepreciation1}</b>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottom: "none",
                              padding: "6px",
                            }}
                          >
                            <b>{totalRowLabel.annualDepreciation2}</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </div>
              </Grid>
            </div>
          </Card>
        </Grid>
      </div>
    </>
  );
}

export default TaxYieldCalculator;
