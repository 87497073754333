import React, { useState, useContext, useEffect } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import MyContext from "../context/appContext";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@material-ui/core";

function LetterGenerator() {
  const appContext = useContext(MyContext);
  let navigate = useNavigate();
  const [dsts, setDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dst1Value, setDst1Value] = useState({});
  const [dst2Value, setDst2Value] = useState({});
  const [dst3Value, setDst3Value] = useState([]);
  const [dst4Value, setDst4Value] = useState([]);
  const [dstName, setDstName] = useState("");
  const [letterData, setLetterData] = useState({
    clientName: "",
    clientRelinquished: "",
    propertyOnPage: "0",
  });

  useEffect(() => {
    getIdData();
  }, []);
  useEffect(() => {
    // Retrieve form data from session storage

    const storedFormData = sessionStorage.getItem("formStateIdLetter");
    console.log("storedFormData:", storedFormData);
    if (storedFormData) {
      const { dstsArray, letterData } = JSON.parse(storedFormData);
      setLetterData(letterData);
      mapDataToState(dstsArray);
    }
  }, []);
  const mapDataToState = async (dstsArray) => {
    if (dstsArray) {
      // Check if dstsArray exists and has a property named "dstsArray"
      const dstObjects = dstsArray; // Assuming the actual dst objects are within a property named "dstsArray"

      console.log("dstObjects:", dstObjects);
      const promises = dstsArray.map(async (val) => {
        const payload = {
          queryStringParameters: {
            tableName: "dsts_table-dev",
            id: val.id,
          },
        };
        console.log("payload:", payload);
        const res = await API.get("dynamoRW", "/dsts", payload);
        return res.Item;
      });

      const results = await Promise.all(promises);
      dstObjects.forEach((dstObject, index) => {
        dstObject.basicInfo = results[index]
          ? results[index].basicInfo.fullLegalNameOfDst
          : ""; // Assuming structure and property names
      });
      const modifiedDstObjects = dstObjects.map((dstObject) => ({
        ...dstObject,
        clientEquity: applyMask(dstObject.clientEquity), // Assuming applyMask function exists and works as intended
      }));

      setDst1Value(modifiedDstObjects[0]);
      setDst2Value(modifiedDstObjects[1]);
      setDst3Value(modifiedDstObjects[2]);
      setDst4Value(modifiedDstObjects[3]);

      // Handle any potential out-of-bounds access if the array is shorter
    } else {
      console.warn(
        "dstsArray or dstsArray.dstsArray is undefined. Skipping data mapping."
      );
    }
  };
  const getIdData = async () => {
    try {
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };
      const res = await API.get("dynamoRW", "/dsts", payload);
      const activeDsts = res.filter((item) => item.is_active !== false);
      const sortedDsts = activeDsts.sort((a, b) =>
        a?.basicInfo?.fullLegalNameOfDst?.localeCompare(
          b.basicInfo.fullLegalNameOfDst
        )
      );
      setDsts(sortedDsts);
    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
    } finally {
      setLoading(false);
    }
  };
  const dstsName = dsts?.map((itm) => {
    return {
      key: itm.id,
      name: itm.basicInfo.fullLegalNameOfDst,
    };
  });
  const applyMask = (inputVal) => {
    const cleanInput = inputVal?.replace(/,/g, "")?.replace(/\.(?=.*\.)/g, "");
    const [wholePart, decimalPart] = cleanInput?.split(".");
    const formattedWholePart = wholePart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (decimalPart !== undefined) {
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      return formattedWholePart;
    }
  };
  const handleChange = (e) => {
    setLetterData({ ...letterData, [e.target.name]: e.target.value });
  };
  const handleClickMenu = (index, name) => {
    console.log("The dst name is......", name);
    setDstName((prevState) => ({
      ...prevState,
      dstName: name,
    }));
  };

  const handleChangeForDst1 = (property, value) => {
    console.log("The properties is......", property, value);
    // const inputVal = e.target.value;
    let maskedInput = value;
    if (property === "clientEquity") {
      maskedInput = applyMask(value);
    }
    console.log("The DSTNAME is......", dstName);
    // setDst1Value((prevState) => ({
    //   ...prevState,
    //   // [property]: value,
    //   [property]: maskedInput,
    //   dstName: dstName,
    // }));
    setDst1Value((prevState) => {
      const updatedState = {
        ...prevState,
        [property]: maskedInput,
      };
      updateSessionStorage(updatedState, dst2Value, dst3Value, dst4Value);
      return updatedState;
    });
  };
  const handleChangeForDst2 = (property, value) => {
    let maskedInput = value;
    if (property === "clientEquity") {
      maskedInput = applyMask(value);
    }

    // setDst2Value((prevState) => ({
    //   ...prevState,
    //   [property]: maskedInput,
    // }));
    setDst2Value((prevState) => {
      const updatedState = {
        ...prevState,
        [property]: maskedInput,
      };
      updateSessionStorage(dst1Value, updatedState, dst3Value, dst4Value);
      return updatedState;
    });
  };
  const handleChangeForDst3 = (property, value) => {
    let maskedInput = value;
    if (property === "clientEquity") {
      maskedInput = applyMask(value);
    }

    // setDst3Value((prevState) => ({
    //   ...prevState,
    //   [property]: maskedInput,
    // }));
    setDst3Value((prevState) => {
      const updatedState = {
        ...prevState,
        [property]: maskedInput,
      };
      updateSessionStorage(dst1Value, dst2Value, updatedState, dst4Value);
      return updatedState;
    });
  };
  const handleChangeForDst4 = (property, value) => {
    let maskedInput = value;
    if (property === "clientEquity") {
      maskedInput = applyMask(value);
    }

    // setDst4Value((prevState) => ({
    //   ...prevState,
    //   [property]: maskedInput,
    // }));
    setDst4Value((prevState) => {
      const updatedState = {
        ...prevState,
        [property]: maskedInput,
      };
      updateSessionStorage(dst1Value, dst2Value, dst3Value, updatedState);
      return updatedState;
    });
  };
  const updateSessionStorage = (dst1, dst2, dst3, dst4) => {
    const hasMeaningfulData = (dst) => dst && dst.id && dst.id.trim() !== "";
    const dstsArray = [dst1, dst2, dst3, dst4].filter(hasMeaningfulData);

    if (dstsArray.length > 0) {
      const formStateIdLetter = {
        dstsArray: dstsArray,
        letterData,
      };
      sessionStorage.setItem(
        "formStateIdLetter",
        JSON.stringify(formStateIdLetter)
      );
    } else {
      console.log("No valid data to store in session.");
    }
  };

  let dstsArray = [];
  if (dst1Value.id) {
    dstsArray.push(dst1Value);
  }
  if (dst2Value?.id) {
    dstsArray.push(dst2Value);
  }
  if (dst3Value?.id) {
    dstsArray.push(dst3Value);
  }
  if (dst4Value?.id) {
    dstsArray.push(dst4Value);
  }

  const handleAddData = () => {
    if (!dstsArray.length) {
      toast(`ID Letter cannot be empty!`, { type: "error" });
      return;
    }
    try {
      const updatedDstsArray = dstsArray.map((item) => {
        if (!item.clientEquity) {
          throw new Error("clientEquity is required.");
          return;
        }

        return {
          ...item,
          clientEquity: item?.clientEquity?.replace(/[^\w\s.-]/gi, ""),
        };
      });

      navigate("/id-letter-report", {
        state: {
          dstsArray: updatedDstsArray,
          letterData,
        },
      });
      const formStateIdLetter = {
        dstsArray: updatedDstsArray,
        letterData,
      };
      sessionStorage.setItem(
        "formStateIdLetter",
        JSON.stringify(formStateIdLetter)
      );
    } catch (error) {
      console.log(error);
      toast(`${error}`, { type: "error" });
    }
  };

  const sessionClear = () => {
    sessionStorage.clear("formStateIdLetter");

    const resetState = (setState) => {
      setState((prevFormData) => {
        if (!prevFormData) return {};
        return Object.fromEntries(
          Object.keys(prevFormData).map((key) => [key, ""])
        );
      });
    };

    resetState(setDst1Value);
    resetState(setDst2Value);
    resetState(setDst3Value);
    resetState(setDst4Value);
    resetState(setLetterData);
  };

  return (
    <div style={{ margin: "2rem" }}>
      <Grid container spacing={2}>
        <Card
          style={{
            width: "100%",
            marginTop: "1rem",
            marginLeft: "1rem",
            padding: "1rem 0rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1.5rem",
            }}
          >
            <h4 style={{ margin: "0rem 4rem" }}>ID LETTER EXHIBIT GENERATOR</h4>
          </div>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>Client Name</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="clientName"
                value={letterData.clientName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>Client Relinquished Property</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="clientRelinquished"
                value={letterData.clientRelinquished}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>#Properties on Page 1(if unsure:0)</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="propertyOnPage"
                onChange={handleChange}
                value={letterData.propertyOnPage}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 1 Name</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                select
                size="small"
                margin="normal"
                fullWidth
                style={{ margin: "0px" }}
                name="dst1name"
                label="Select DST"
                value={dst1Value?.id || ""}
                onChange={(e) => handleChangeForDst1("id", e.target.value)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">Clear</MenuItem>
                {dstsName?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.key}
                    onClick={(e) => handleClickMenu(ind, option.name)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 1 Client Equity</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="dst1Equity"
                value={dst1Value.clientEquity}
                onChange={(e) =>
                  handleChangeForDst1("clientEquity", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 2 Name</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                select
                size="small"
                margin="normal"
                fullWidth
                style={{ margin: "0px" }}
                name="dst1name"
                label="Select DST"
                value={dst2Value?.id || ""}
                onChange={(e) => handleChangeForDst2("id", e.target.value)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">Clear</MenuItem>
                {dstsName?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.key}
                    onClick={(e) => handleClickMenu(ind, option.name)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 2 Client Equity</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="dst2Equity"
                value={dst2Value?.clientEquity}
                onChange={(e) =>
                  handleChangeForDst2("clientEquity", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 3 Name</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                select
                size="small"
                margin="normal"
                fullWidth
                style={{ margin: "0px" }}
                name="dst1name"
                label="Select DST"
                value={dst3Value?.id || ""}
                onChange={(e) => handleChangeForDst3("id", e.target.value)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">Clear</MenuItem>
                {dstsName?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.key}
                    onClick={(e) => handleClickMenu(ind, option.name)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 3 Client Equity</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="dst3Equity"
                value={dst3Value?.clientEquity}
                onChange={(e) =>
                  handleChangeForDst3("clientEquity", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 4 Name</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                select
                size="small"
                margin="normal"
                fullWidth
                style={{ margin: "0px" }}
                name="dst1name"
                label="Select DST"
                value={dst4Value?.id || ""}
                onChange={(e) => handleChangeForDst4("id", e.target.value)}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="">Clear</MenuItem>
                {dstsName?.map((option, ind) => (
                  <MenuItem
                    key={ind}
                    value={option.key}
                    onClick={(e) => handleClickMenu(ind, option.name)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: "4rem",
              paddingRight: "4rem",
              paddingTop: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spacing={2}
          >
            <Grid item xs={4} className="Grid-Gap">
              <h5>DST 4 Client Equity</h5>
            </Grid>
            <Grid item xs={8} className="Grid-Gap">
              <TextField
                size="small"
                fullWidth
                name="dst4Equity"
                value={dst4Value?.clientEquity}
                onChange={(e) =>
                  handleChangeForDst4("clientEquity", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              columnGap: "10px",
              justifyContent: "end",
              alignItems: "center",
              width: "100%",
              marginTop: "1rem",
            }}
          >
            <Button onClick={handleAddData} type="submit" variant="contained">
              View Report
            </Button>
            <Button
              onClick={sessionClear}
              type="submit"
              variant="contained"
              style={{ margin: "0rem 4rem 0rem 0rem" }}
            >
              Reset
            </Button>
          </div>
        </Card>
      </Grid>
    </div>
  );
}

export default LetterGenerator;
