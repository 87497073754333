import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { API, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import MyContext from "../context/appContext";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";

let matchedPdfUrl;
let pdfUrlList = [];

const styles = (theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    "& thead th": {
      border: "1px solid white",
      backgroundColor: "black",
      color: "white",
      padding: "0.2rem",
      lineHeight: "1rem",
    },
    "& thead th.column7": {
      backgroundColor: "black",
    },
    "& tbody td": {
      border: "1px solid black",
    },
  },
});

function SniffTest({ classes }) {
  let navigate = useNavigate();

  const [tableRows, setTableRows] = useState(() => {
    const initialRows = [...Array(6)].map(() => ({
      id: "",
      approvalDate: "",
      equityAvailability: "",
      sponsor: "",
      sector: "",
      ltv: "",
      upreit: "",
      y1Yield: "",
      y1Payout: "",
      intrestPeriod: "",
      loanRate: "",
      loanType: "",
      y1DSCR: "",
      loadedCapRate: "",
      purchaseCapRate: "",
      loadOnEquity: "",
      loadOnPurchase: "",
      initialReserves: "",
      year5ReservesBalance: "",
      proForma: "",
      year7BreakEven: "",
      msatier: "",
    }));
    return initialRows;
  });
  const [dsts, setDsts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(false);
  const [pdfURLs, setPdfURLs] = useState([]);
  const [pdfURLsThumbnails, setPdfURLsThumbnails] = useState([]);

  const appContext = useContext(MyContext);
  const [sort, setSort] = useState({
    column: "",
    direction: "asc",
  });

  useEffect(() => {
    getIdData();
  }, []);

  const getIdData = async () => {
    appContext.updateState("loading", true);
    try {
      const payload = {
        queryStringParameters: {
          tableName: "dsts_table-dev",
          id: "id-name",
        },
      };

      const res = await API.get("dynamoRW", "/dsts", payload);
      
      const activeDsts = res.filter((item) => item.is_active !== false);
      const sortedDsts = activeDsts.sort((a, b) => {
        const dateA = new Date(a.basicInfo.concordeApprovalDate || "-");
        const dateB = new Date(b.basicInfo.concordeApprovalDate || "-");
        return dateB - dateA;
      });

      setDsts(sortedDsts);
      const imageUrls = await fetchData(sortedDsts);
      if (imageUrls) {
        await handleDropdownChange(sortedDsts);
        appContext.updateState("loading", false);
      } else {
        appContext.updateState("loading", false);
      }
    } catch (error) {
      console.log(error.message);
      toast(`No Data Found! ${error.message}`, { type: "error" });
      appContext.updateState("loading", false);
    }

  };
  const fetchData = async (res) => {
    try {
      appContext.updateState("loading", true);
      Storage.configure({
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET,
          region: process.env.REACT_APP_LAMBDA_REGION,
        },
      });
      const result = await Storage.list("", { level: "public" });

      const pdfFiles = result.filter((item) => item.key.endsWith(".png"));
      const PPM = result.filter((item) => item.key.includes("thumbnail_pdf"));
      const imageNames = res
        .map((dst) => dst.uploaded_pdf__thumbnails)
        .filter(Boolean);
      const matchedImages = result.filter((item) =>
        imageNames.includes(item.key)
      );

      const imageUrls = await Promise.all(
        matchedImages.map(async (image) => {
          const url = await Storage.get(image.key, { level: "public" });
          return { key: image.key, url };
        })
      );

      imageUrls.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "base" })
      );

      const pdfURLss = await Promise.all(
        pdfFiles.map(async (pdfFile) => {
          const fileName = pdfFile.key;
          const url = await Storage.get(pdfFile.key, { level: "public" });
          return {
            key: fileName,
            url,
          };
        })
      );
      const PPMfiles = await Promise.all(
        PPM.map(async (pdfFile) => {
          const fileName = pdfFile.key;
          const url = await Storage.get(pdfFile.key, { level: "public" });
          return {
            key: fileName,
            url,
          };
        })
      );

      pdfURLss.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "base" })
      );

      setPdfURLs(imageUrls);
      pdfUrlList = imageUrls;
      setPdfURLsThumbnails((pre) => {
        return [...pre, ...PPMfiles];
      });

      appContext.updateState("loading", false);
      return true;
    } catch (error) {
      appContext.updateState("loading", false);
      console.error("Error retrieving PDFs:", error);
    }
  };

  const handleDropdownChange = (dsts) => {
    appContext.updateState("loading", true);
    try {
      if (dsts.length === 0) {
        appContext.updateState("loading", false);
        return;
      }

      const cleanedData = dsts.map((selectedItem) => {
        const cleanedDebt = selectedItem?.basicInfo?.offeringDebt.replace(
          /[^0-9.]/g,
          ""
        );
        const cleanedEquity = selectedItem?.basicInfo?.offeringEquity.replace(
          /[^0-9.]/g,
          ""
        );
        const cleanedNetOperatingIncome =
          selectedItem?.basicInfo?.year1NetOperatingIncome.replace(
            /[^0-9.]/g,
            ""
          );
        const cleanedTotalDebtService =
          selectedItem?.basicInfo?.year1TotalDebtService.replace(
            /[^0-9.]/g,
            ""
          );
        const cleanedPurchasePrice =
          selectedItem?.basicInfo?.purchasePrice.replace(/[^0-9.]/g, "");
        const cleanedRentGrowthAssump =
          selectedItem?.basicInfo?.rentGrowthAssumption.replace(/[^0-9.]/g, "");
        const cleanedTotalDstRes =
          selectedItem?.proceeds?.totalDstReserves.replace(/[^0-9.]/g, "");
        const cleanedYear5ResBalance =
          selectedItem?.proceeds?.year5ReservesBalance.replace(/[^0-9.]/g, "");
        const cleanedOOCosts = selectedItem?.proceeds?.OOCosts.replace(
          /[^0-9.]/g,
          ""
        );
        const cleanedBrokerDealerCommissions =
          selectedItem?.proceeds?.brokerDealerCommissions.replace(
            /[^0-9.]/g,
            ""
          );
        const cleanedFinancingFee =
          selectedItem?.proceeds?.financingFee.replace(/[^0-9.]/g, "");
        const cleanedOtherFees = selectedItem?.proceeds?.otherFees.replace(
          /[^0-9.]/g,
          ""
        );
        const cleanedThirdPartyClosingCosts =
          selectedItem?.proceeds?.thirdPartyClosingCosts.replace(
            /[^0-9.]/g,
            ""
          );
        const cleanedTransactionCommission =
          selectedItem?.proceeds?.transactionCommission.replace(/[^0-9.]/g, "");
        const cleanedWholesalingCosts =
          selectedItem?.proceeds?.wholesalingCosts.replace(/[^0-9.]/g, "");
        const y1Yield = selectedItem?.yields?.year1Yield.replace(
          /[^0-9.]/g,
          ""
        );
        const loanRate = selectedItem?.basicInfo?.loanRate.replace(
          /[^0-9.]/g,
          ""
        );

        const totalOfProceeds =
          +cleanedOOCosts +
          +cleanedBrokerDealerCommissions +
          +cleanedWholesalingCosts +
          +cleanedTransactionCommission +
          +cleanedFinancingFee +
          +cleanedOtherFees +
          +cleanedThirdPartyClosingCosts;

        const interestPeriod = selectedItem?.basicInfo?.interestOnlyPeriod;
        const numericValue = parseInt(interestPeriod, 10) || "N/A";
        matchedPdfUrl = pdfUrlList.find((pdf) => {
          return pdf.key === selectedItem.uploaded_pdf__thumbnails;
        });

        return {
          id: selectedItem.id,
          name: selectedItem?.basicInfo?.fullLegalNameOfDst || "N/A",
          approvalDate: selectedItem?.basicInfo?.concordeApprovalDate,
          equityAvailability:
            selectedItem?.basicInfo?.equityAvailability || "N/A",
          sponsor: selectedItem?.basicInfo?.nameOfTheSponsor || "N/A",
          sector: selectedItem?.basicInfo?.dstPropertiesSector || "N/A",
          ltv:
            cleanedDebt !== "" && cleanedEquity !== ""
              ? (
                  (+cleanedDebt / (+cleanedDebt + +cleanedEquity)) *
                  100
                ).toFixed(2)
              : "N/A",
          upreit: selectedItem?.basicInfo?.upreit || "N/A",
          y1Yield: y1Yield || "N/A",
          y1Payout:
            cleanedNetOperatingIncome !== "" &&
            cleanedTotalDebtService !== "" &&
            cleanedEquity !== "" &&
            y1Yield !== ""
              ? (
                  (+cleanedNetOperatingIncome - +cleanedTotalDebtService) /
                  ((+y1Yield / 100) * +cleanedEquity)
                ).toFixed(2)
              : "N/A",
          intrestPeriod: numericValue,
          loanRate: loanRate || "N/A",
          loanType: selectedItem?.basicInfo?.fixedVariable || "N/A",
          y1DSCR:
            cleanedNetOperatingIncome !== "" &&
            cleanedDebt !== "" &&
            loanRate !== ""
              ? (
                  +cleanedNetOperatingIncome /
                  (+cleanedDebt * (+loanRate / 100))
                ).toFixed(2)
              : "N/A",
          loadedCapRate:
            cleanedNetOperatingIncome !== "" &&
            cleanedDebt !== "" &&
            cleanedEquity !== ""
              ? (
                  (+cleanedNetOperatingIncome /
                    (+cleanedEquity + +cleanedDebt)) *
                  100
                ).toFixed(2)
              : "N/A",

          purchaseCapRate:
            cleanedNetOperatingIncome !== "" && cleanedPurchasePrice !== ""
              ? (
                  (+cleanedNetOperatingIncome / +cleanedPurchasePrice) *
                  100
                ).toFixed(2)
              : "N/A",

          loadOnEquity:
            cleanedEquity !== "" &&
            cleanedDebt !== "" &&
            cleanedTotalDstRes !== "" &&
            cleanedPurchasePrice !== ""
              ? (
                  ((+cleanedEquity +
                    +cleanedDebt -
                    +cleanedTotalDstRes -
                    +cleanedPurchasePrice) /
                    +cleanedEquity) *
                  100
                ).toFixed(2)
              : "N/A",

          loadOnPurchase:
            cleanedEquity !== "" &&
            cleanedDebt !== "" &&
            cleanedTotalDstRes !== "" &&
            cleanedPurchasePrice !== ""
              ? (
                  ((+cleanedEquity +
                    +cleanedDebt -
                    +cleanedTotalDstRes -
                    +cleanedPurchasePrice) /
                    +cleanedPurchasePrice) *
                  100
                ).toFixed(2)
              : "N/A",

          initialReserves:
            cleanedTotalDstRes !== "" && cleanedEquity !== ""
              ? ((+cleanedTotalDstRes / +cleanedEquity) * 100).toFixed(2)
              : "N/A",

          year5ReservesBalance:
            cleanedYear5ResBalance !== "" && cleanedTotalDstRes !== ""
              ? (
                  +(+cleanedYear5ResBalance / +cleanedTotalDstRes) * 100
                ).toFixed(2)
              : "N/A",

          proForma: cleanedRentGrowthAssump || "N/A",
          year7BreakEven:
            totalOfProceeds !== "" && cleanedPurchasePrice !== ""
              ? (
                  (((+totalOfProceeds + +cleanedPurchasePrice) / 0.96 -
                    +cleanedPurchasePrice) /
                    7 /
                    +cleanedPurchasePrice) *
                  100
                ).toFixed(2)
              : "N/A",
          msaTier: selectedItem?.basicInfo?.msaTier || "N/A",
          states: selectedItem?.basicInfo?.states || "N/A",
          repCommission: selectedItem?.repCommission || "N/A",
          thumbnailUrl: matchedPdfUrl ? matchedPdfUrl.url : null,
        };
      });
      const sortedData = cleanedData.sort((a, b) => {
        const dateA = !a.approvalDate ? new Date(0) : new Date(a.approvalDate);
        const dateB = !b.approvalDate ? new Date(0) : new Date(b.approvalDate);

        if (dateA.getTime() === 0 && dateB.getTime() !== 0) {
          return 1;
        } else if (dateA.getTime() !== 0 && dateB.getTime() === 0) {
          return -1;
        } else {
          return dateB - dateA;
        }
      });

      setTableRows(sortedData);
      setErrMessage(false);
      appContext.updateState("loading", false);
    } catch (error) {
      appContext.updateState("loading", false);
      console.log(error.message);
    }
  };
  const SortingIcon = ({ direction, column }) => {
    return (sort.column === column && direction) === "asc" ? (
      <ArrowUpward style={{ fontSize: 18, cursor: "pointer" }} />
    ) : (
      <ArrowDownward style={{ fontSize: 18, cursor: "pointer" }} />
    );
  };
  const handleSort = (column) => {
    const isAsc = sort.column === column && sort.direction === "asc";
    const sortedRows = [...tableRows].sort((a, b) => {
      const keyA = getColumnValue(a, column);
      const keyB = getColumnValue(b, column);

      if (keyA === "N/A") return 1;
      if (keyB === "N/A") return -1;

      if (
        column === "loanType" ||
        column === "name" ||
        column === "sector" ||
        column === "sponsor" ||
        column === "upreit"
      ) {
        return isAsc ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA);
      } else {
        const numA = parseFloat(keyA);
        const numB = parseFloat(keyB);
        return isAsc ? numA - numB : numB - numA;
      }
    });

    setTableRows(sortedRows);
    setSort({ column, direction: isAsc ? "desc" : "asc" });
  };

  const getColumnValue = (row, column) => {
    if (column === "cisApprovalDate") {
      return new Date(row.cisApprovalDate).getTime();
    } else {
      return row[column];
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      {loading === true ? null : (
        <div style={{ margin: "2rem" }}>
          {dsts.length > 0 ? (
            <Grid container spacing={2}>
              <Card
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  marginLeft: "1rem",
                  padding: "1rem 0rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3
                    style={{
                      margin: "1rem ",
                    }}
                  >
                    DST Data Grid
                  </h3>
                </div>
                <div style={{ margin: "0rem 0rem" }}>
                  <Grid>
                    <div
                      style={{
                        margin: "0rem 0rem",

                        marginRight: "0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    >
                      <Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        ></div>
                        <div
                          style={{
                            overflowX: "auto",
                            maxHeight: "80vh",
                            position: "sticky",
                          }}
                        >
                          <Table
                            className={classes.table}
                            aria-label="excel-like table"
                            stickyHeader
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    textAlign: "start",
                                  }}
                                  onClick={() => handleSort("name")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src="/faviconPNG.png"
                                      style={{ height: "14px" }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "start",
                                  }}
                                  onClick={() => handleSort("name")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                    }}
                                  >
                                    DST Name{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="name"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("approvalDate")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    CIS Approval Date{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="cisApprovalDate"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() =>
                                    handleSort("equityAvailability")
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Equity Availability{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="equityAvailability"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("sponsor")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Sponsor{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="sponsor"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("sector")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Sector{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="sector"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("ltv")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    LTV{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="ltv"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    borderRight: "1px solid black",
                                    borderBottom: "1px solid white",
                                  }}
                                  onClick={() => handleSort("upreit")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    UPREIT{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="upreit"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    borderRight: "1px solid black",
                                    borderBottom: "1px solid black",
                                  }}
                                ></TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("y1Yield")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Y1 Yield %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="y1Yield"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("y1Payout")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Y1 Payout Ratio After DST Fees{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="y1Payout"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("intrestPeriod")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    I/O Period{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="intrestPeriod"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("loanRate")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Loan Rate %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="loanRate"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("loanType")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Loan Type{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="loanType"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("y1DSCR")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Y1 DSCR %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="y1DSCR"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("loadedCapRate")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Fully Loaded Cap Rate %
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="loadedCapRate"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("purchaseCapRate")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Purchase Cap Rate %
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="purchaseCapRate"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("loadOnEquity")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Load on Equity (ex-Reserves) %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="loadOnEquity"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("loadOnPurchase")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Load on Purchase (ex-Reserves) %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="loadOnPurchase"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("initialReserves")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Initial Reserves % of Offering{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="initialReserves"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() =>
                                    handleSort("year5ReservesBalance")
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Year 5 Reserves Balance % of offering{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="year5ReservesBalance"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("proForma")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Pro Forma Rent Growth Assump. %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="proForma"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("year7BreakEven")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    7 Year Break-even Growth Rate %{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="year7BreakEven"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleSort("msaTier")}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    MSA Tier 1-3{" "}
                                    <SortingIcon
                                      style={{ marginLeft: "auto" }}
                                      direction={sort.direction}
                                      column="msaTier"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    States
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "auto",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    Rep Commission
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableRows.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    style={{
                                      width: "20%",
                                      cursor: "pointer",
                                      margin: 0,
                                      padding: "0px 0px",
                                    }}
                                  >
                                    <img
                                      src={
                                        row?.thumbnailUrl
                                          ? row.thumbnailUrl
                                          : "https://static.vecteezy.com/system/resources/previews/005/337/799/non_2x/icon-image-not-found-free-vector.jpg"
                                      }
                                      alt="logo"
                                      style={{
                                        width: "6rem",
                                        height: "4rem",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "20%",
                                      cursor: "pointer",
                                      margin: 0,
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      <TextField
                                        size="small"
                                        margin="normal"
                                        variant="standard"
                                        style={{
                                          margin: "0px",
                                          width: "20rem",
                                          cursor: "pointer",
                                          border: "0px",
                                        }}
                                        onClick={() =>
                                          navigate(`/print-dst/${row.id}`)
                                        }
                                        value={row.name}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          style: {
                                            border: "none",
                                            cursor: "pointer",
                                          },
                                        }}
                                      ></TextField>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      style={{
                                        width: "8rem",
                                        textAlign: "center",
                                        border: "0px",
                                      }}
                                      value={
                                        row.approvalDate
                                          ? new Date(
                                              row.approvalDate
                                            ).toLocaleDateString("en-US")
                                          : "N/A"
                                      }
                                      InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                        style: {
                                          border: "none",
                                          cursor: "pointer",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      style={{
                                        width: "10rem",
                                        textAlign: "center",
                                        backgroundColor:
                                          row?.equityAvailability ==
                                          ">10 million available"
                                            ? "#93d97c"
                                            : row?.equityAvailability ==
                                              "<10 million available"
                                            ? "#f2e26b"
                                            : row?.equityAvailability ==
                                              "Almost fully reserved"
                                            ? "yellow"
                                            : row?.equityAvailability ==
                                              "Taking backups"
                                            ? "#f58484"
                                            : "white",
                                      }}
                                      value={row?.equityAvailability}
                                      InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      style={{
                                        width: "12rem",
                                        textAlign: "center",
                                      }}
                                      value={row.sponsor}
                                      InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      style={{
                                        width: "8rem",
                                        textAlign: "center",
                                      }}
                                      value={row.sector}
                                      InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "6rem",
                                          textAlign: "center",
                                        }}
                                        value={row.ltv}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "7rem",
                                          textAlign: "center",
                                        }}
                                        value={row.upreit}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{ backgroundColor: "black" }}
                                  >
                                    <div
                                      style={{ backgroundColor: "black" }}
                                    ></div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.y1Yield && row.y1Yield <= 4.0
                                          ? "#e0eff9"
                                          : row.y1Yield > 4.0 &&
                                            row.y1Yield < 5.0
                                          ? "#98b7dd"
                                          : row.y1Yield >= 5.0
                                          ? "#6694cb"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "6rem",
                                          textAlign: "center",
                                        }}
                                        value={row.y1Yield}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.y1Payout && row.y1Payout < 1
                                          ? "#f58484"
                                          : row.y1Payout >= 1
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "8rem",
                                          textAlign: "center",
                                        }}
                                        value={row.y1Payout}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.intrestPeriod &&
                                        row.intrestPeriod <= 6.0
                                          ? "#f58484"
                                          : row.intrestPeriod > 6.0 &&
                                            row.intrestPeriod < 8.0
                                          ? "#f2e26b"
                                          : row.intrestPeriod >= 8.0
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "7rem",
                                          textAlign: "center",
                                        }}
                                        value={row.intrestPeriod}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.loanRate && row.loanRate <= 5.0
                                          ? "#93d97c"
                                          : row.loanRate > 5 &&
                                            row.loanRate < 6.5
                                          ? "#f2e26b"
                                          : row.loanRate >= 6.5
                                          ? "#f58484"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "8rem",
                                          textAlign: "center",
                                        }}
                                        value={row.loanRate}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.loanType &&
                                        row.loanType === "Variable"
                                          ? "#f58484"
                                          : row.loanType === "Fixed"
                                          ? "#93d97c"
                                          : row.loanType === "V Capped"
                                          ? "#f2e26b"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "7rem",
                                          textAlign: "center",
                                        }}
                                        value={row.loanType}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.y1DSCR && row.y1DSCR <= 2.0
                                          ? "#f58484"
                                          : row.y1DSCR > 2 && row.y1DSCR < 2.5
                                          ? "#f2e26b"
                                          : row.y1DSCR >= 2.5
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "6rem",
                                          textAlign: "center",
                                        }}
                                        value={row.y1DSCR}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.loadedCapRate &&
                                        row.loadedCapRate <= 4.0
                                          ? "#f58484"
                                          : row.loadedCapRate > 4.0 &&
                                            row.loadedCapRate < 5.0
                                          ? "#f2e26b"
                                          : row.loadedCapRate >= 5.0
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "8rem",
                                          textAlign: "center",
                                        }}
                                        value={row.loadedCapRate}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.purchaseCapRate &&
                                        row.purchaseCapRate <= 4.5
                                          ? "#f58484"
                                          : row.purchaseCapRate > 4.5 &&
                                            row.purchaseCapRate < 5.5
                                          ? "#f2e26b"
                                          : row.purchaseCapRate >= 5.5
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.purchaseCapRate}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.loadOnEquity &&
                                        row.loadOnEquity <= 15.0
                                          ? "#93d97c"
                                          : row.loadOnEquity > 15.0 &&
                                            row.loadOnEquity < 18.0
                                          ? "#f2e26b"
                                          : row.loadOnEquity >= 18.0
                                          ? "#f58484"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.loadOnEquity}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.loadOnPurchase &&
                                        row.loadOnPurchase >= 13.0
                                          ? "#f58484"
                                          : row.loadOnPurchase > 9.0 &&
                                            row.loadOnPurchase < 13.0
                                          ? "#f2e26b"
                                          : row.loadOnPurchase <= 9.0
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.loadOnPurchase}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.initialReserves &&
                                        row.initialReserves >= 10.0
                                          ? "#f58484"
                                          : row.initialReserves > 5.0 &&
                                            row.initialReserves < 10.0
                                          ? "#f2e26b"
                                          : row.initialReserves <= 5.0 &&
                                            row.initialReserves > 0.01
                                          ? "#93d97c"
                                          : Math.abs(
                                              row.initialReserves - 0.0
                                            ) < 0.001
                                          ? "#f58484"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.initialReserves}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.year5ReservesBalance &&
                                        row.year5ReservesBalance <= 50
                                          ? "#f58484"
                                          : row.year5ReservesBalance > 50 &&
                                            row.year5ReservesBalance < 80
                                          ? "#f2e26b"
                                          : row.year5ReservesBalance >= 80
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.year5ReservesBalance}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.proForma && row.proForma >= 3.5
                                          ? "#f58484"
                                          : row.proForma > 2.5 &&
                                            row.proForma < 3.5
                                          ? "#f2e26b"
                                          : row.proForma <= 2.5
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.proForma}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.year7BreakEven &&
                                        row.year7BreakEven >= 3.0
                                          ? "#f58484"
                                          : row.year7BreakEven > 2.0 &&
                                            row.year7BreakEven < 3.0
                                          ? "#f2e26b"
                                          : row.year7BreakEven <= 2.0
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "9rem",
                                          textAlign: "center",
                                        }}
                                        value={row.year7BreakEven}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              %
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      backgroundColor:
                                        row.msaTier && row.msaTier === "3"
                                          ? "#f58484"
                                          : row.msaTier === "2"
                                          ? "#f2e26b"
                                          : row.msaTier === "1"
                                          ? "#93d97c"
                                          : "initial",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "7rem",
                                          textAlign: "center",
                                        }}
                                        value={row.msaTier}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "20rem",
                                          textAlign: "center",
                                          flexWrap: "wrap",
                                        }}
                                        value={row.states}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0px 10px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div>
                                      <TextField
                                        size="small"
                                        variant="standard"
                                        style={{
                                          width: "7rem",
                                          textAlign: "center",
                                          flexWrap: "wrap",
                                        }}
                                        value={row.repCommission}
                                        InputProps={{
                                          readOnly: true,
                                          disableUnderline: true,
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            <TableRow>
                              <TableCell
                                style={{
                                  borderRight: "1px solid white",
                                  borderBottom: "1px solid white",
                                  backgroundColor: "black",
                                  color: "white",
                                  lineHeight: "0.5rem",
                                }}
                              >
                                NOTES:
                              </TableCell>
                              {[...Array(23)].map((_, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    borderRight: "1px solid white",
                                    borderBottom: "1px solid white",
                                    backgroundColor: "black",
                                    lineHeight: "0.5rem",
                                  }}
                                ></TableCell>
                              ))}
                            </TableRow>
                            <TableRow
                              style={{
                                backgroundColor: "#d9dee7",
                              }}
                            >
                              <TableCell
                                style={{
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                  padding: "0px 20px",
                                }}
                                colSpan={9}
                              >
                                <ul>
                                  <li>
                                    Y1 Payout Ratio equals [current NOI – debt
                                    service] divided by the first-year investor
                                    distribution
                                  </li>
                                  <li>
                                    Y1 DSCR equals the current NOI divided by
                                    the annual loan payment
                                  </li>
                                  <li>
                                    Load on Equity equals the total load over
                                    purchase price divided by the offering
                                    equity, not including reserves
                                  </li>
                                  <li>
                                    Load on Purchase equals the total load over
                                    purchase price divided by the purchase
                                    price, not including reserves
                                  </li>
                                  <li>
                                    Pro Forma Rent Growth Assumption may not
                                    include a higher rate assumed during an
                                    initial stabilization period
                                  </li>
                                  <li>
                                    7 Year Break-even Growth Rate equals the
                                    straight-line annual property appreciation
                                    required for the DST to return investors’
                                    capital upon a sale in Year 7, assuming
                                  </li>
                                </ul>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  // padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                  padding: "0px",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#e0eff9" }}>{"<=4.0"}</p>
                                  <p style={{ color: "#e0eff9" }}>{">=4.0"}</p>
                                  <p style={{ color: "#98b7dd" }}>{"<5.0"}</p>
                                  <p style={{ color: "#6694cb" }}>{">=5.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{"<1"}</p>
                                  <p style={{ color: "green" }}>{">=1"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {" <= 6.0"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>{">6.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{" < 8.0"}</p>
                                  <p style={{ color: "green" }}>{">=8.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{">= 6.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 6.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 5.0"}</p>
                                  <p style={{ color: "green" }}>{"<= 5.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {"Variable"}
                                  </p>
                                  <p style={{ color: "green" }}>{"Fixed"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"V Cap"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{"<= 2.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 2"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{" < 2.5"}</p>
                                  <p style={{ color: "green" }}>{">= 2.5"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{"<= 4.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{" > 4.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{" < 5.0"}</p>
                                  <p style={{ color: "green" }}>{">= 5.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{"<= 4.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 4.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 5.5"}</p>
                                  <p style={{ color: "green" }}>{">= 5.5"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {">= 18.0"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 18.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 15.0"}</p>
                                  <p style={{ color: "green" }}>{"<= 15.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {">= 13.0"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 13.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 9.0"}</p>
                                  <p style={{ color: "green" }}>{"<= 9.0"}</p>
                                </div>
                              </TableCell>

                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {">= 10.0"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 10.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 5.0"}</p>
                                  <p style={{ color: "green" }}>{"<= 5.0"}</p>
                                  <p style={{ color: "#f58484" }}>{"=0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>
                                    {"<= (50%)"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>
                                    {"> (50%)"}
                                  </p>
                                  <p style={{ color: "#bcbc4b" }}>
                                    {"< (80%)"}
                                  </p>
                                  <p style={{ color: "green" }}>{">=(80%)"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{">= 3.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 3.5"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 2.5"}</p>
                                  <p style={{ color: "green" }}>{"<= 2.5"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{">= 3.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"< 3.0"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"> 2.0"}</p>
                                  <p style={{ color: "green" }}>{"<= 2.0"}</p>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  margin: 0,
                                  padding: 0,
                                  borderRight: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <div>
                                  <p style={{ color: "#f58484" }}>{"3"}</p>
                                  <p style={{ color: "#bcbc4b" }}>{"2"}</p>
                                  <p style={{ color: "green" }}>{"1"}</p>
                                </div>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </div>
              </Card>
            </Grid>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(SniffTest);
